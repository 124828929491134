<template>
    <div class="bullets-composer">
        <backdrop-element v-if="suggestionString != ''" @click.native="suggestionString = ''" />
        <search-memo-modal @selected-memo="insertLinkMemo" />
        <div class="steps-buttons container">
            <div class="allow-close" @click="goToHome">
                <img src="@assets/img/icons/x.svg" alt="Close modal">
            </div>
            <button
                v-if="isDraftMemo"
                type="button"
                class="btn btn-outline-primary ml-auto"
                @click="saveDraft"
            >
                Drafts
            </button>
            <button
                class="btn btn-primary next-button"
                :class="{ 'ml-auto' : !isDraftMemo, 'ml-3' : isDraftMemo }"
                :disabled="!isBulletStepValid"
                @click="goNextStep"
            >
                Next
            </button>
        </div>
        <div class="step-container-border">
            <transition name="slide-fade">
                <suggestions-list
                    v-if="suggestionString != ''"
                    :suggestion-string="suggestionString"
                    :suggestion-type="suggestionType"
                    @selected-suggestion="selectedSuggestion"
                />
            </transition>
            <iframe-interface
                ref="iframe_interface"
                @add-new-bullet="addNewBullet"
                @on-bullet-change="onBulletChange"
                @on-title-change="onTitleChange"
                @on-bullet-focus="onBulletFocus"
                @on-bullet-selection-change="onBulletSelectionChange"
                @on-mention="onMention"
                @on-hash-tag="onHashtag"
                @on-mention-end="onFinishedSelectingSuggestion"
                @on-hash-tag-end="onFinishedSelectingSuggestion"
                @on-change="onChange"
                @on-editor-loaded="onEditorLoaded"
            />
            <toolbar-actions
                :bullet-display-type="bulletDisplayType"
                :current-selected-bullet="currentSelectedBullet"
                :current-selection="currentSelection"
                :is-multipart-memo="isMultipartMemo"
                @toggle-bullet-display-type="toggleBulletDisplayType"
                @add-new-bullet="addNewBullet"
                @format-selection="formatSelection"
                @insert-images="insertImages"
                @insert-mention="insertMentionText"
                @insert-hash-tag="insertHashTagText"
                @link-memo="$modal.show('search-memo')"
                @show-multipart-selector="$emit('show-multipart-selector')"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: "BulletsComposer",
    components: {
        IframeInterface: () => import(/* webpackChunkName: "iframe-interface" */ "./iframe-interface"),
        ToolbarActions: () => import(/* webpackChunkName: "toolbar-action" */ "./toolbar-actions"),
        SuggestionsList: () => import(/* webpackChunkName: "suggestions-list" */ "../suggestions-list/"),
        BackdropElement: () => import(/* webpackChunkName: "backdrop-element" */ "../backdrop-element"),
        SearchMemoModal: () => import(/* webpackChunkName: "search-memo-modal" */ "@c/organisms/modals/search-memo")
    },
    props: {
        isDraftMemo: {
            type: Boolean,
            required: true
        },
        isMultipartMemo: {
            type: Boolean,
            default: false
        },
        value: {
            type: Object,
            required: true
        },
        bulletDisplayType: {
            type: Object,
            required: true
        },
        isBulletStepValid: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isEditorReady: false,
            suggestionString: "",
            suggestionType: "mentions",
            currentSelectedBullet: {
                prettyText: "",
                rawText: ""
            },
            currentSelection: {
                formats: {},
                selection: {}
            },
            bulletDisplayTypes: {
                bullet: {
                    id: "1",
                    name: "bullet"
                },
                numeric: {
                    id: "2",
                    name: "numeric"
                }
            }
        };
    },
    methods: {
        onEditorLoaded() {
            this.$emit("editor-loaded");
            this.$emit("is-loading", false);
            this.isEditorReady = true;
        },
        addNewBullet() {
            this.$refs.iframe_interface.addNewBullet();
        },
        formatSelection(format) {
            this.$refs.iframe_interface.formatSelection(format);
        },
        insertImages(file) {
            this.$emit("associate-images-to-memo", file);
            this.$refs.iframe_interface.insertImages([file]);
        },
        setCurrentSelectedBullet(bullet) {
            this.currentSelectedBullet = bullet;
        },
        onTitleChange(title) {
            this.$emit("input", { title });
        },
        onChange(bullets) {
            if (this.isEditorReady) {
                this.$emit("input", { bullets });
            }
        },
        onBulletChange(bullet) {
            this.setCurrentSelectedBullet(bullet);
        },
        onBulletFocus(bullet) {
            this.setCurrentSelectedBullet(bullet);
        },
        onBulletSelectionChange(selection) {
            this.currentSelection = selection;
        },
        async onMention(username) {
            this.suggestionString = username;
            this.suggestionType = "mentions";
        },
        async onHashtag(tag) {
            this.suggestionString = tag;
            this.suggestionType = "tags";
        },
        onFinishedSelectingSuggestion() {
            this.suggestionString = "";
        },
        toggleBulletDisplayType() {
            if (this.bulletDisplayType.name == this.bulletDisplayTypes.bullet.name) {
                this.$emit("set-display-type", this.bulletDisplayTypes.numeric);
                this.$refs.iframe_interface.setBulletDisplayType(this.bulletDisplayTypes.numeric.name);
            } else {
                this.$emit("set-display-type", this.bulletDisplayTypes.bullet);
                this.$refs.iframe_interface.setBulletDisplayType(this.bulletDisplayTypes.bullet.name);
            }
        },
        insertMentionText() {
            this.$refs.iframe_interface.insertMentionText();
        },
        insertHashTagText() {
            this.$refs.iframe_interface.insertHashtagText();
        },
        insertText(text) {
            this.$refs.iframe_interface.insertText(text);
        },
        insertLinkMemo(memo) {
            this.$modal.hide("search-memo");

            const memoToInsert = {
                id: memo.id,
                displayname: memo.writer.displayname,
                title: memo.title,
                slug: memo.slug
            }

            this.$refs.iframe_interface.insertLinkMemo(memoToInsert);
        },
        selectedSuggestion(suggestion) {
            if (this.suggestionType === "mentions") {
                this.$refs.iframe_interface.insertMention(suggestion);
            } else {
                this.$refs.iframe_interface.insertHashtag(suggestion);
            }
        },
        async setContent(part) {
            await this.$refs.iframe_interface.setContent(part);
        },
        goToHome() {
            this.$router.push({ name: "web-app-home" });
        },
        goNextStep() {
            this.$emit("go-next-step");
        },
        saveDraft() {
            this.$emit("save-draft");
        }
    }
}
</script>

<style lang="scss" scoped>
.bullets-composer {
    display: flex;
    flex-direction: column;
    /* 5vh is the padding top separation from navbar and 65px is the navbar height */
    height: calc(100vh - 5vh - 65px);

    @media(max-width: $md) {
        /* 2vh is the padding top separation from navbar, 58.88px is the navbar height
        and 103px is the mobile-tab-bar height at the bottom */
        height: calc(100vh - 2vh - 55.88px - 103px);
    }

    .steps-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 4vh;

        @media(max-width: $lg) {
            max-width: 100%;

            .btn {
                padding: 12px 24px;
            }
        }

        h1 {
            margin-bottom: 0;
        }

        @media(max-width: $lg) {
            padding: 20px;
        }
    }

    .step-container-border {
        overflow: hidden;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;

        @media(max-width: $lg) {
            border: 0;
        }

        .iframe-interface {
            order: 1;

            @media(max-width: $lg) {
                order: 2;
            }
        }

        .toolbar-actions {
            order: 2;

            @media(max-width: $lg) {
                order: 1;
                max-width: 100%;
            }
        }
    }

    .suggestions-list {
        position: absolute;
        padding: 15px 0;
        width: 100%;
        z-index: 999;

        @media(max-width: $md) {
            height: 100vh;
            overflow: scroll;
        }

        .suggestion {
            cursor: pointer;
            padding: 10px 0;
            z-index: 100;
            position: relative;
        }
    }

    input::-webkit-input-placeholder { /* Edge */
        color: #979797!important;
        font-size: 14px;
    }

    input:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #979797!important;
        font-size: 14px;
    }

    input::placeholder {
        color: #979797!important;
        font-size: 14px;
    }

    .allow-close {
        display: none;
        width: 20px;
        height: 20px;
        cursor: pointer;
        z-index: 2;

        img {
            width: 100%;
        }

        @media(max-width: $lg) {
            display: flex;
        }
    }
}
</style>
